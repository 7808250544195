<template>
  <div>
    <Header />

    <section class="guide-gm">
      <span class="h1" v-if="this.store.getters.isDesktop">
        Sommaire du guide du garde meuble
      </span>

      <div v-if="this.store.getters.isMobile" class="titles">
        <h1>
          {{ this.current.title }}
        </h1>
        <h2>
          {{ this.current.header }}
        </h2>
      </div>

      <aside v-if="this.store.getters.isDesktop" class="pages-list">
        <a v-for="page in this.pages" :href="page.url">
          <p>
            <img :src="'https://www.location-gardemeuble.fr/' + page.icon" :alt="page.title" width="75" height="75" />
          </p>
          <b>
            {{ page.title }}
          </b>
        </a>
      </aside>

      <main class="content">
        <div class="menu-left">
          <div>
            <b>A lire sur cette page :</b>
          </div>

          <ul>
            <li v-for="question in this.current.questions">
              <a style="cursor: pointer;" @click.prevent="this.scrollToQuestion( question.slug )">
                {{ question.title }}
              </a>
            </li>
          </ul>

          <div v-if="this.store.getters.isDesktop">
            <b>Trouvez et réservez votre garde-meuble</b>
          </div>

          <div class="embedded-search" v-if="this.store.getters.isDesktop">
            <SearchBar />
          </div>

          <div v-if="this.store.getters.isDesktop">
            <b>Bon à savoir :</b>
          </div>

          <ul class="tips" v-if="this.store.getters.isDesktop">
            <li v-for="tip in this.current.tips">
              <span>
                &bull; {{ tip }}
              </span>
            </li>
          </ul>
        </div>

        <div class="main-content" itemscope itemtype="https://schema.org/FAQPage">

          <div v-if="this.store.getters.isDesktop">
            <h1>
              {{ this.current.title }}
            </h1>
            <h2>
              {{ this.current.header }}
            </h2>
          </div>

          <article v-if="this.current?.slug === 'guide-taille'" style="text-align: center;">
            <a :href="$router.resolve({name: 'calculateur'}).href" class="button button-block" style="display: inline-block; background: rgb(53, 56, 138); color: white !important;">
              Accéder au calculateur de volume
            </a>
          </article>

          <article v-if="this.current?.slug === 'guide-prix' && this.current?.prices?.length > 0" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
            <div id="prices" style="position:relative; top:-80px"></div>

            <h3 itemprop="name">
              Combien coûtent les garde-meubles en région ?
            </h3>

            <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
              <table style="width: 50%; text-align: center;">
                <tr>
                  <th style="text-align: left;">Region</th>
                  <th>Prix moyen au m<sup>2</sup></th>
                </tr>
                <tr v-for="price in this.current.prices">
                  <td style="text-align: left;">{{ price.name }}</td>
                  <td>{{ price.price }} €</td>
                </tr>
              </table>
            </div>
          </article>

          <article v-for="question in this.current.questions" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
            <div :id="question.slug" style="position:relative; top:-80px"></div>

            <h3 itemprop="name">
              {{ question.title }}
            </h3>

            <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
              <p v-html="question.text" itemprop="text"></p>
            </div>
          </article>
        </div>

      </main>
    </section>

    <Footer />
  </div>
</template>

<script>
import { Options, setup, Vue } from "vue-class-component";
import { computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import { useMeta } from "vue-meta";

@Options( {
  components : {
    Header    : defineAsyncComponent( () => import("@/components/Header.vue") ),
    Footer    : defineAsyncComponent( () => import("@/components/Footer.vue") ),
    SearchBar : defineAsyncComponent( () => import("@/components/SearchBar.vue") ),
  }
} )
export default class extends Vue {
  store   = useStore( key );
  pages   = [];
  current = {};

  meta = setup( () => useMeta( computed( () => {
    return {
      title       : [{
        content : "Guide ultime du garde-meuble - " + (this.current.title ?? ""),
        vmid    : "title",
        name    : "title"
      }],
      description : {
        content : "Guide ultime du garde-meuble - " + (this.current.title ?? "") + " - " + (this.current.header ?? ""),
        vmid    : "description",
        name    : "description"
      }
    };
  } ) ) );

  scrollToQuestion( question_id )
  {
    window.scrollTo( { top : (document.getElementById( question_id )?.offsetTop ?? 0), behavior : 'smooth' } );
  }

  async getCurrentPage()
  {
    let request = await fetch( this.store.state.api + "guide/pages/" + this.$route.params.slug, {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json     = await request.json();
    this.current = json.data;
  }

  async getOtherPages()
  {
    let request = await fetch( this.store.state.api + "guide/pages", {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json = await request.json();

    // Remove current page from list
    json.data  = json.data.filter( ( page ) => page.id !== this.current.id );
    this.pages = json.data;
  }

  async mounted()
  {
    try
    {
      await this.getCurrentPage();
      await this.getOtherPages();
    }
    catch( e )
    {
      console.error( e );
      // TODO : Better error handling
    }

    setTimeout( () => {
      this.store.state.loading = false;

      // Remove div#preload from DOM
      let preload = document.getElementById( "preload" );
      if( preload )
      {
        window.scrollTo( { top : 0 } );
        preload.remove();
      }
    }, this.store.state.delay );
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

h1, h2
{
  margin     : auto;
  text-align : center;
}

h1, .h1
{
  display     : block;
  margin      : 0.25rem auto !important;
  text-align  : center;
  line-height : 1.5em;
  font-size   : 1.3em;

  @media (max-width : 1023px)
  {
    font-weight : bold !important;
  }
}

h2
{
  margin-top : 1em;
  font-size  : 1em;
}

.blog-link
{
  display : block;

  &:hover
  {
    color           : $lightBlue !important;
    text-decoration : underline;
  }
}

.titles
{
  padding     : 1em;
  line-height : 1.5em;
}

.pages-list
{
  display         : flex;
  justify-content : center;
  align-items     : stretch;
  padding         : 0 !important;

  a
  {
    display    : block;
    flex       : 1;
    padding    : 0.5em 0 1.5em;
    transition : all linear 200ms;

    &:hover
    {
      background : $bgDarkOrange;
    }

    p
    {
      margin-bottom : 0.1em;
    }

    b
    {
      display     : block;
      font-size   : 0.75em;
      color       : #2F3C45;
      padding     : 0 0.5em;
      line-height : 1.5em;
    }
  }
}

main.content
{
  display   : flex;
  flex-wrap : nowrap;

  a
  {
    color : $lightBlue !important;

    &:hover
    {
      text-decoration : underline;
    }
  }

  @media (max-width : 1023px)
  {
    flex-direction : column;

    & > .menu-left
    {
      border    : none !important;
      margin    : auto;
      max-width : initial !important;
      width     : 100%;

      a
      {
        display    : block;
        background : #EFEFEF;
        border     : 1px solid #BABABA;
        padding    : 0.5em;
      }
    }

    & > .main-content
    {
      padding : 1.5em !important;
    }
  }

  .menu-left
  {
    max-width    : 450px;
    padding      : 1.5em;
    color        : #2F3C45;
    font-size    : 0.85em;

    border-right : 1px solid grey;

    ul
    {
      font-size  : 0.85em;
      list-style : none;
      padding    : 0;

      &.tips
      {
        color : $fontColor;
      }

      li
      {
        margin-bottom : 0.75em;
        line-height   : 1.5em;

        a
        {
          color      : #2F3C45 !important;
          transition : all linear 200ms;

          &:hover
          {
            color : $lightBlue !important;
          }
        }
      }
    }
  }

  .main-content
  {
    flex    : 1;
    padding : 2em 4em;

    article
    {
      font-size   : 0.8em;
      margin-top  : 4em;
      line-height : 1.75em;

      @media (max-width : 1023px)
      {
        margin-top    : 0;
        margin-bottom : 3em;
      }

      h3
      {
        font-weight : bold;
      }

      p
      {
        color      : $fontColor;
        text-align : justify;

        a
        {
          color : $fontColor;

          &:hover
          {
            color           : $lightBlue !important;
            text-decoration : underline;
          }
        }
      }

      img
      {
        max-width : 100%;
      }
    }
  }
}
</style>
